.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
  }
  40% {
      transform: translateY(-30px);
  }
  60% {
      transform: translateY(-15px);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeInDown {
  animation: fadeInDown 1s ease-out forwards;
}

.animation-delay-1 {
  animation-delay: 0.5s;
}

.animation-delay-2 {
  animation-delay: 1s;
}





#contact
.slide-in {
  transform: translateY(-100%); /* Start the element outside of the visible area */
  transition: transform 0.5s ease;
  transform: translateY(0); /* Move element into the visible area */
}






@keyframes slideInLeftFromMiddle {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-5%);
  }
}

@keyframes slideInRightFromMiddle {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(5%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-slideInLeftFromMiddle {
  animation: slideInLeftFromMiddle 1s forwards;
}

.animate-slideInRightFromMiddle {
  animation: slideInRightFromMiddle 1s forwards;
}

.animate-fadeIn {
  animation: fadeIn 1s forwards;
}

.opacity-0 {
  opacity: 0;
}
